<script setup lang="ts">
import {
  isTypeArticle,
  isTypeCompany,
  isTypePage,
  isTypeReport,
  isTypeStaff,
} from "~/types/contentful";

const { baseUrl } = useRuntimeConfig();

const buttonUpVisible = ref(false);

const scrollToTop = () => {
  window.scrollTo(0, 0);
};
const watchScroll = () => {
  buttonUpVisible.value = window.scrollY > 64;
};

if (import.meta.client) {
  window.addEventListener("scroll", watchScroll);
}

const page = useLoadedContent();

const contentConfig = useContentConfig();

const metaTitle = computed(() => {
  let title;

  if (page.value) {
    if (isTypePage(page.value)) {
      // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
      title = page.value.fields.metaTitle || page.value.fields.title;
    } else if (isTypeArticle(page.value) || isTypeReport(page.value)) {
      // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
      title = page.value.fields.metaTitle || page.value.fields.title;
    } else if (isTypeCompany(page.value) || isTypeStaff(page.value)) {
      title = page.value.fields.name;
    }
  }

  // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
  return title || page.value?.fields.slug || "";
});

const metaDescription = computed(() => {
  let description;

  if (page.value) {
    if (isTypePage(page.value)) {
      description
        // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
        = page.value.fields.metaDescription || page.value.fields.excerpt;
    } else if (isTypeArticle(page.value) || isTypeReport(page.value)) {
      description
        // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
        = page.value.fields.metaDescription || page.value.fields.subtitle;
    }
  }

  // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
  return description || contentConfig.value?.metaDescriptionFallback || "";
});

const metaImage = computed(() => {
  let url;

  if (page.value) {
    if (isTypeArticle(page.value) || isTypeReport(page.value)) {
      url = page.value.fields.image;
    } else if (isTypePage(page.value)) {
      url = page.value.fields.thumbnail;
    }
  }

  url ??= contentConfig.value?.metaImageFallback;

  return url
    ? `https:${url.fields.file?.url.replace(
      "downloads.",
      "images.",
    ) ?? ""}?f=top&fit=fill&w=1200&h=630`
    : "";
});

const isArticle = computed(
  () => page.value && (isTypeArticle(page.value) || isTypeReport(page.value)),
);

const metaTitleBase = computed(() =>
  page.value
  && ((isTypePage(page.value) && !page.value.fields.hideMetaTitleBase)
    || !isTypePage(page.value))
    ? contentConfig.value?.metaTitleBase
    : "",
);

useHead({
  title: metaTitle,
  ...(metaTitleBase.value ? {} : { titleTemplate: null }),
});

useServerSeoMeta({
  description: metaDescription,
  ogDescription: metaDescription,
  ogImage: metaImage,
  ogTitle: () =>
    `${metaTitle.value}${
      metaTitleBase.value ? ` | ${metaTitleBase.value}` : ""
    }`,
  ogType: isArticle.value ? "article" : "website",
  ogUrl: baseUrl + useRoute().fullPath,
  twitterCard: "summary_large_image",
  twitterDescription: metaDescription,
  twitterImage: metaImage,
  twitterTitle: () =>
    `${metaTitle.value}${
      metaTitleBase.value ? ` | ${metaTitleBase.value}` : ""
    }`,
});
</script>

<template>
  <div>
    <AppHeader />
    <NuxtLoadingIndicator />
    <main>
      <slot />
    </main>

    <ClientOnly>
      <OtherGlobalNotification />
      <OtherKnowledgeHubSurvey />
    </ClientOnly>

    <div
      class="btn fixed bottom-3 right-3 z-[1002] size-[46px] opacity-100 transition duration-500 lg:hidden"
      :class="!buttonUpVisible && 'pointer-events-none !opacity-0'"
      @click="
        scrollToTop();
        buttonUpVisible = false;
      "
    >
      <NuxtIcon
        name="fairr:arrow"
        size="24"
        class="rotate-180"
      />
    </div>

    <AppFooter />
  </div>
</template>

<style lang="scss" scoped>
.nuxt-loading-indicator {
  @apply sticky z-40 -mt-[3px] bg-gradient-to-r from-sapphire to-light-royal-blue #{!important};
}
</style>
